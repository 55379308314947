import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../actions/cartActions";
import MessageBox from "../components/MessageBox";
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import axios from "axios";
import xmgLogo from "../Assets/images/xmg.png";
import uuid from 'react-uuid'
export default function CartScreen(props) {
  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split("=")[1])
    : 1;
  const cart = useSelector((state) => state.cart);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { cartItems } = cart;
  const dispatch = useDispatch();
  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };  
  const [currentInvoice,setCurrentInvoice]=useState('')
  const [currentCartItem,setCurrentCartItem]=useState(null)
  const  dataUploaderFOrOrder = () => {
    if(cartItems.length){ // handleSubmit();
    // props.history.push("/signin?redirec=shipping");
    // generateInvoice()
  
const stringifyCartItems= JSON.stringify(cartItems)
console.log(stringifyCartItems)
axios.post(`https://messangerapi533cdgf6c556.amaprods.com/api/users/storePurchasedOrder/`,{
  product:stringifyCartItems,
  invoice_id:currentInvoice,
  user_id:userInfo._id,
  product_id:currentInvoice

})
.then((res)=>{console.log('success');document.getElementById('redirectToPayment').click()})
.catch((err)=>{console.log(err);alert(err)})
}else{
  alert('Please Add Some Items In Cart To Checkout')
}
   
  
  };
  const  dataUploaderFOrOrderPN = () => {
    // handleSubmit();
    // props.history.push("/signin?redirec=shipping");
    // generateInvoice()
    if(cartItems.length){
      const stringifyCartItems= JSON.stringify(cartItems)
axios.post(`https://messangerapi533cdgf6c556.amaprods.com/api/users/storePurchasedOrder/`,{
  product:stringifyCartItems,
  invoice_id:currentInvoice,
  user_id:userInfo._id,
  product_id:currentInvoice

})
.then((res)=>{console.log('success');document.getElementById('redirectToPaymentPN').click()})
.catch((err)=>{console.log(err);alert(err)})

  
    }else{
      alert('Please Add Some Items In Cart To Checkout')
    }

  };
  const  checkoutHandler = () => {
    // handleSubmit();
    // props.history.push("/signin?redirec=shipping");
    // generateInvoice()
    localStorage.removeItem('cartItems')
  
  };

  const handleSubmit = () => {
    axios
      .get(
        "https://pecunovus.org/api/req.php?type=check_merchant_transaction_status&merch_key=FRT578QqsFPN9RC9jNyRaTNZDJwrd4ykToZXsICSwaKD2BeIWeCM7Ost9fcWjGNAJEgdSyV4RPwxzc3LYQJKIR1R0YWC8mYv5iIlO1JqE9EPWWddOsEsvB7InZeAPCxqwioxBJWaTxoh&inv=OSoihsaHDiosad9832hdsSHd89HSa87dt32hdosHhs89sd098u2hnbNSoyfhj3i2j09fu2fnosdapHS09238mfMsi"
      )
      .then((data) => {
        console.log(data, "data");
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const generateInvoice=()=>{

setCurrentCartItem(cartItems);
console.log(currentCartItem,currentInvoice,"generateInvoice")
  }

  // generateInvoice()
  useEffect(() => {
    let invoice= uuid()
    setCurrentInvoice(invoice);
   
  }, [])
  return (
    <div className="row top">
      <div className="col-2">
        <h1>Shopping Cart</h1>
        {cartItems.length === 0 ? (
          <MessageBox>
            Cart is empty. <Link to="/">Tap to Shop</Link>
          </MessageBox>
        ) : (
          <ul>
            {cartItems.map((item) => (
              <li key={item.product}>
                <div className="row">
                  <div>
                    <img
                      src={item.image}
                      alt={item.name}
                      className="small"
                    ></img>
                  </div>
                  <div className="min-30">
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </div>
                  <div>
                    {/* <select
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product, Number(e.target.value))
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>

                        ))}
                      </select> */}
                    <option value={1}>{1}</option>
                  </div>
                  <div>{item.price} XMG</div>
                  <div>
                    <button
                      type="button"
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="col-1">
        <div className="card card-body">
          <ul>
            <li>
              <h2>
                Subtotal ({cartItems.reduce((a, c) => a + c.qty, 0)} items) :
                {cartItems.reduce((a, c) => a + c.price * c.qty, 0)} XMG/PN
              </h2>
            </li>
            <li>
              {/* <button onClick={checkoutHandler}>checkoutHandler</button> */}
            <form method="POST" action="https://megahoot.org/mh_api_checkout.php">
<input type="hidden" name="mid" value="FRT578QqsFPN9RC9jNyRaTNZDJwrd4ykToZXsICSwaKD2BeIWeCM7Ost9fcWjGNAJEgdSyV4RPwxzc3LYQJKIR1R0YWC8mYv5iIlO1JqE9EPWWddOsEsvB7InZeAPCxqwioxBJWaTxoh" />
<input type="hidden" name="inv" value={currentInvoice} />
<input type="hidden" name="subtotal" value={cartItems.reduce((a, c) => a + c.price * c.qty, 0)}/>
<input type="hidden" name="curr" value="XMG" />
 {cartItems.map((data) => {
                  console.log(data);
                  return (
                    <>
                      <input type="hidden" name="tp[]" value="1" />
                      <input type="hidden" name="name[]" value={data.name} />
                      <input type="hidden" name="amount[]" value={data.price} />
                      <input type="hidden" name="q[]" value={data.qty} />
                    </>
                    
                  );

                })}

                <input
                  type="submit"
                onClick={checkoutHandler}
                id="redirectToPayment"
                  value="Proceed to checkout"
                  className="primary block"
                  style={{
                    display:'none',
                    padding: "5px",
                    backgroundColor: "rgb(241, 195, 89)",
                    borderRadius: "5px",
                    color: "black",
                  }}
                />
              </form>
              <form method="POST" action="https://pecunovus.org/pecu_api_checkout.php">
<input type="hidden" name="mid" value="FRT578QqsFPN9RC9jNyRaTNZDJwrd4ykToZXsICSwaKD2BeIWeCM7Ost9fcWjGNAJEgdSyV4RPwxzc3LYQJKIR1R0YWC8mYv5iIlO1JqE9EPWWddOsEsvB7InZeAPCxqwioxBJWaTxoh" />

<input type="hidden" name="inv" value={currentInvoice} />
<input type="hidden" name="subtotal" value={cartItems.reduce((a, c) => a + c.price * c.qty, 0)}/>
<input type="hidden" name="curr" value="PECU" />
 {cartItems.map((data) => {
                  console.log(data);
                  return (
                    <>
                      <input type="hidden" name="tp[]" value="1" />
                      <input type="hidden" name="name[]" value={data.name} />
                      <input type="hidden" name="amount[]" value={data.price} />
                      <input type="hidden" name="q[]" value={data.qty} />
                    </>
                    
                  );

                })}

                <input
                  type="submit"
                onClick={checkoutHandler}
                id="redirectToPaymentPN"
                  value="Proceed to checkout"
                  className="primary block"
                  style={{
                    display:'none',
                    padding: "5px",
                    backgroundColor: "rgb(241, 195, 89)",
                    borderRadius: "5px",
                    color: "black",
                  }}
                />
              </form>
              <button className="primary block" onClick={dataUploaderFOrOrder} disabled={cartItems.length?false:true}>Proceed to checkout(XMG)</button>
              <button className="primary block" onClick={dataUploaderFOrOrderPN} disabled={cartItems.length?false:true}>Proceed to checkout(PN)</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
