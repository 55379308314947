import React, { useEffect, useState } from "react";
import Product from "../components/Product";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../actions/productActions";
import LandingCarousel from "../components/Carousel";
import LazyLoad from "react-lazyload";
import AuctionCarousel from "../components/AuctionCarousel";
import Sidebar from "../components/Sidebar";
import axios from "axios";

export default function HomeScreen() {
  const dispatch = useDispatch();
  var [category, setCategory] = useState([]);
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const [delasData, setdealsData] = useState([]);
  const [status, setStatus] = useState([]);
  const fetchAlldata = () => {
    axios.get(`https://api.fortisab.com/api/users/getAllProductCategory`).then((res) => {
      res.data.forEach((element) => {
        if (element.categoryItem && !category.includes(element.categoryItem)) {
          setCategory((oldArray) => [...oldArray, element.categoryItem]);
          category = category.filter(function (value, index, array) {
            return array.indexOf(value) == index;
          });
        }
      });
    });
  };
  useEffect(() => {
    fetchAlldata();
  }, []);
  useEffect(() => {
    dispatch(listProducts({}));
  }, [dispatch]);

  useEffect(() => {
    setdealsData(products);
  }, [products]);

  const handleApply = (min, max) => {
    let filterData = products.filter(
      (res) => res.price >= min && res.price <= max
    );
    setdealsData(filterData);
  };
  return (
    <div style={{display:"flex"}}>
      <div>
        <Sidebar
          handleApply={handleApply}
          status={status}
          setStatus={setStatus}
          category={category}
        />
      </div>
      <div>
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <LazyLoad>
            {" "}
            <div>
              {(status.includes("hot_delas") || !status.length) && (
                <>
                  <h1 className="glow hot-deal">
                    Hot Deals{" "}
                    <i
                      class="fa fa-cart-plus"
                      style={{ color: "#1FA8F8", fontSize: "30px" }}
                      aria-hidden="true"
                    ></i>{" "}
                  </h1>
                  <LandingCarousel data={delasData} />
                </>
              )}
              {(status.includes("live_actions") || !status.length) && (
                <>
                  <h1 className="glow">
                    Live Auction{" "}
                    <i
                      class="fa fa-tint"
                      style={{ color: "orangeRed", fontSize: "30px" }}
                      aria-hidden="true"
                    ></i>{" "}
                  </h1>
                  <AuctionCarousel data={delasData} />
                </>
              )}
              {(status.includes("marketplaces") || !status.length) && (
                <>
                  <h1 className="glow marketplace-title">
                    Marketplace{" "}
                    <i
                      class="fa fa-shopping-bag "
                      style={{ color: "#DA2754", fontSize: "30px" }}
                      aria-hidden="true"
                    ></i>{" "}
                  </h1>
                  <div className="row center Marketplace">
                    {delasData?.map((product) => (
                         <Product key={product._id} product={product}></Product>
                    ))}
                  </div>
                </>
              )}
            </div>
          </LazyLoad>
        )}
      </div>
    </div>
  );
}
