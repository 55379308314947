import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Product from "./Product";
import Bidding from "./Bidding";
import "../Assets/sass/Carousel.scss";
import "../Assets/sass/style.scss";
import { useHistory } from "react-router";
export default function LandingCarousel(props) {
  let history = useHistory();
  console.log(history.location.pathname.replace("/", ""));
  const products = props.data;
  return (
    <div className="liveauction-secrion" style={{ maxWidth: "70vw" }}>
      <Carousel
        interval={2000}
        centerMode={true}
        showArrows={true}
        centerSlidePercentage={30}
        infiniteLoop={true}
        // selectedItem={4}
        showStatus={false}
        autoPlay={true}
        showIndicators={false}
      >
        {products.map((product) => (
          <>
            {history.location.pathname.replace("/", "") === "bidding" ? (
              <Bidding key={product._id} product={product} />
            ) : (
              <Product key={product._id} product={product} />
            )}
          </>
        ))}
      </Carousel>
    </div>
  );
}
