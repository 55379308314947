import {
  BID_LIST_REQUEST,
  BID_LIST_SUCCESS,
  BID_LIST_FAIL,
  BID_DETAILS_REQUEST,
  BID_DETAILS_SUCCESS,
  BID_DETAILS_FAIL,
  //   BID_CREATE_REQUEST,
  //   BID_CREATE_SUCCESS,
  //   BID_CREATE_FAIL,
  //   BID_CREATE_RESET,
  //   BID_UPDATE_REQUEST,
  //   BID_UPDATE_SUCCESS,
  //   BID_UPDATE_FAIL,
  //   BID_UPDATE_RESET,
  //   BID_DELETE_REQUEST,
  //   BID_DELETE_SUCCESS,
  //   BID_DELETE_FAIL,
  //   BID_DELETE_RESET,
  //   BID_REVIEW_CREATE_RESET,
} from "../constants/bidConstants";

export const bidListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case BID_LIST_REQUEST:
      return { loading: true };
    case BID_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case BID_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bidDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case BID_DETAILS_REQUEST:
      return { loading: true };
    case BID_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case BID_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const productCreateReducer = (state = {}, action) => {
// switch (action.type) {
//   case PRODUCT_CREATE_REQUEST:
//     return { loading: true };
//   case PRODUCT_CREATE_SUCCESS:
//     return { loading: false, success: true, product: action.payload };
//   case PRODUCT_CREATE_FAIL:
//     return { loading: false, error: action.payload };
//   case PRODUCT_CREATE_RESET:
//     return {};
//   default:
//     return state;
// }
// };

// export const productUpdateReducer = (state = {}, action) => {
// switch (action.type) {
//   case PRODUCT_UPDATE_REQUEST:
//     return { loading: true };
//   case PRODUCT_UPDATE_SUCCESS:
//     return { loading: false, success: true };
//   case PRODUCT_UPDATE_FAIL:
//     return { loading: false, error: action.payload };
//   case PRODUCT_UPDATE_RESET:
//     return {};
//   default:
//     return state;
// }
// };

// export const productDeleteReducer = (state = {}, action) => {
// switch (action.type) {
//   case PRODUCT_DELETE_REQUEST:
//     return { loading: true };
//   case PRODUCT_DELETE_SUCCESS:
//     return { loading: false, success: true };
//   case PRODUCT_DELETE_FAIL:
//     return { loading: false, error: action.payload };
//   case PRODUCT_DELETE_RESET:
//     return {};
//   default:
//     return state;
// }
// };
