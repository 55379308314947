export const BID_LIST_REQUEST = "BID_LIST_REQUEST";
export const BID_LIST_SUCCESS = "BID_LIST_SUCCESS";
export const BID_LIST_FAIL = "BID_LIST_FAIL";

export const BID_DETAILS_REQUEST = "BID_DETAILS_REQUEST";
export const BID_DETAILS_SUCCESS = "BID_DETAILS_SUCCESS";
export const BID_DETAILS_FAIL = "BID_DETAILS_FAIL";

export const BID_CREATE_REQUEST = 'BID_CREATE_REQUEST';
export const BID_CREATE_SUCCESS = 'BID_CREATE_SUCCESS';
export const BID_CREATE_FAIL = 'BID_CREATE_FAIL';
export const BID_CREATE_RESET = 'BID_CREATE_RESET';

export const BID_UPDATE_REQUEST = 'BID_UPDATE_REQUEST';
export const BID_UPDATE_SUCCESS = 'BID_UPDATE_SUCCESS';
export const BID_UPDATE_FAIL = 'BID_UPDATE_FAIL';
export const BID_UPDATE_RESET = 'BID_UPDATE_RESET';

export const BID_DELETE_REQUEST = 'BID_DELETE_REQUEST';
export const BID_DELETE_SUCCESS = 'BID_DELETE_SUCCESS';
export const BID_DELETE_FAIL = 'BID_DELETE_FAIL';
export const BID_DELETE_RESET = 'BID_DELETE_RESET';

export const BID_REVIEW_CREATE_RESET = 'BID_REVIEW_CREATE_RESET '