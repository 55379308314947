import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listBiddingMine } from "../actions/userActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

export default function BiddingHistoryScreen(props) {
  const bidHistory = useSelector((state) => state.bidHistory);
  const { loading, error, user } = bidHistory;
  console.log(user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listBiddingMine());
  }, [dispatch]);
  return (
    <div>
      <h1>Bidding History</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email </th>
              <th>Bidding Price</th>
              <th>Profuct Id</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {user &&
              user.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.name}</td>
                  <td>{order.email}</td>
                  <td>{order.biddingPrice}</td>
                  <td>{order.productId}</td>
                  <td>
                    <button
                      type="button"
                      className="small"
                    //   onClick={() => {
                    //     props.history.push(`/order/${order._id}`);
                    //   }}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
