import axios from "../../node_modules/axios/index";
import {
  BID_LIST_REQUEST,
  BID_LIST_SUCCESS,
  BID_LIST_FAIL,
  BID_DETAILS_REQUEST,
  BID_DETAILS_SUCCESS,
  BID_DETAILS_FAIL,
  //   BID_CREATE_REQUEST,
  //   BID_CREATE_SUCCESS,
  //   BID_CREATE_FAIL,
  //   BID_CREATE_RESET,
  //   BID_UPDATE_REQUEST,
  //   BID_UPDATE_SUCCESS,
  //   BID_UPDATE_FAIL,
  //   BID_UPDATE_RESET,
  //   BID_DELETE_REQUEST,
  //   BID_DELETE_SUCCESS,
  //   BID_DELETE_FAIL,
  //   BID_DELETE_RESET,
  //   BID_REVIEW_CREATE_RESET,
} from "../constants/bidConstants";

export const listBidding = () => async (dispatch) => {
  dispatch({
    type: BID_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`https://api.fortisab.com/api/bidding`);
    dispatch({ type: BID_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BID_LIST_FAIL, payload: error.message });
  }
};
export const detailsBid = (productID) => async (dispatch) => {
  dispatch({ type: BID_DETAILS_REQUEST, payload: productID });
  try {
    const { data } = await axios.get(
      `https://api.fortisab.com/api/bidding/${productID}`
    );
    dispatch({ type: BID_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BID_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
// export const createProduct = () => async (dispatch, getState) => {
//   dispatch({ type: PRODUCT_CREATE_REQUEST });
//   const {
//     userSignin: { userInfo },
//   } = getState();
//   try {
//     const { data } = await axios.post(
//       "/api/products",
//       {},
//       {
//         headers: { Authorization: `Bearer ${userInfo.token}` },
//       }
//     );
//     dispatch({
//       type: PRODUCT_CREATE_SUCCESS,
//       payload: data.product,
//     });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({ type: PRODUCT_CREATE_FAIL, payload: message });
//   }
// };
