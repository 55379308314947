import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../node_modules/axios/index';
import moment from '../../node_modules/moment/moment';
import { listOrderMine } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import PurchasedProduct from '../components/purchasedProduct'
export default function OrderHistoryScreen(props) {
  const orderMineList = useSelector((state) => state.orderMineList);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const { loading, error, orders } = orderMineList;
  const dispatch = useDispatch();
  const [products,setProducts]=useState([])

  useEffect(() => {
    dispatch(listOrderMine());
  }, [dispatch]);

const fetchMyPurchasedOrder=()=>{
  axios.post('https://messangerapi533cdgf6c556.amaprods.com/api/users/showPurchasedOrder',{
    user_id:userInfo._id
  }).then((res)=>{
  res.data.message.forEach((e)=>{

     const saveProduct= JSON.parse(e.product)
     saveProduct.dateOfPurchased=moment(e.createdAt).calendar()
 
     
  
 
console.log(saveProduct,moment(e.createdAt).calendar(),"sky")
axios.get(`https://megahoot.org/api/req.php?type=check_merchant_transaction_status&merch_key=FRT578QqsFPN9RC9jNyRaTNZDJwrd4ykToZXsICSwaKD2BeIWeCM7Ost9fcWjGNAJEgdSyV4RPwxzc3LYQJKIR1R0YWC8mYv5iIlO1JqE9EPWWddOsEsvB7InZeAPCxqwioxBJWaTxoh&inv=${e.invoice_id}`)
.then((res)=>{console.log(res.data.status,typeof(res.data.status),"sky xmg")
if(res.data.status=="1"){
  setProducts(oldArray => [...oldArray,saveProduct]);
}
})
.catch(err=>console.log(err))

axios.get(`https://pecunovus.org/api/req.php?type=check_merchant_transaction_status&merch_key=FRT578QqsFPN9RC9jNyRaTNZDJwrd4ykToZXsICSwaKD2BeIWeCM7Ost9fcWjGNAJEgdSyV4RPwxzc3LYQJKIR1R0YWC8mYv5iIlO1JqE9EPWWddOsEsvB7InZeAPCxqwioxBJWaTxoh&inv=${e.invoice_id}`)
.then((res)=>{console.log(res.data.status,typeof(res.data.status),"sky pn")
if(res.data.status=="1"){
  setProducts(oldArray => [...oldArray,saveProduct]);
}
})
.catch(err=>console.log(err))


  })
 
  })
  .then(()=> console.log(products))
  .catch((err)=>console.log(err))
}

  useEffect(() => {
    fetchMyPurchasedOrder()
  }, []);
  return (
    <div>
      <h1>Order History</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>{"Products Purchased by "+ userInfo.name}</th>
              {/* <th>DATE</th>
              <th>TOTAL</th>
              <th>PAID</th>
              <th>DELIVERED</th>
              <th>ACTIONS</th> */}
            </tr>
          </thead>
          {/* <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.createdAt.substring(0, 10)}</td>
                <td>{order.totalPrice.toFixed(2)}</td>
                <td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>
                <td>
                  {order.isDelivered
                    ? order.deliveredAt.substring(0, 10)
                    : 'No'}
                </td>
                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      props.history.push(`/order/${order._id}`);
                    }}
                  >
                    Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
      
       */}
        </table>
       
      )}
      <div style={{display:'flex',flexWrap:'wrap'}}> {products.length?products.map((e)=>(
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}> <PurchasedProduct key={e[0]._id} product={e[0]} dateOfPurchased={e.dateOfPurchased}></PurchasedProduct></div>

)):null}</div>
      
    </div>
  );
}