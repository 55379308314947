import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
const Sidebar = ({ handleApply, status, setStatus, category }) => {
  const [sideDrawer, setSideDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [statusMenu,setStatusMenu]= useState(true)
  const [categoriesMenu,setCategoriesMenu]= useState(true)
  const [saleMenu,setsalesMenu]= useState(true)
  const [priceMenu,setPriceMenu]= useState(true)
  let history = useHistory();
  const [searchResults, setSearchResults] = React.useState([]);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  React.useEffect(() => {
    const results = category.filter((person) =>
      person.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
  }, [searchTerm]);
  const [condition, setCondtion] = useState({ min: "", max: "" });
  const handleCheckStatus = (data) => {
    if (status.includes(data)) {
      let updatedStatus = status.filter((res) => res !== data);
      setStatus(updatedStatus);
    } else {
      setStatus(status.concat(data));
    }
  };

  return (
    <div>
      {sideDrawer ? (
        <div className="side-drawer w3-container w3-center w3-animate-left">
          <div className="left">
            <div className="p1">
              <div>
                <p className="fa fa-sliders"></p>
                <span className="text">Filter</span>
              </div>
              <i
                className="fa fa-arrow-left"
                onClick={() => {
                  setSideDrawer(false);
                }}
              ></i>
            </div>
            <div className="p2">
              <div className="head">
                <p className="text">Status</p>
                {/* <input className="tick" type="checkbox" /> */}
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <span
                  style={{
                    border: `${
                      status.includes("hot_delas") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("hot_delas")}
                >
                  Hot deal
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("on_actions") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("on_actions")}
                >
                  On Auction
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("marketplaces") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("marketplaces")}
                >
                  Marketplace
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("live_actions") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("live_actions")}
                >
                  Live auction
                </span>
              </div>
            </div>
            <div className="p3">
              <div className="head">
                <p className="text">Price</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="r1 United-States">
                  <select name="" id="">
                  <option value="2">XMG</option>
                    <option value="1" active>
                      United States Dollar(USD)
                    </option>
                  
                  </select>
                  <p className="fa fa-chevron-down"></p>
                </div>
                <div className="r2 mitter">
                  <input
                    type="number"
                    name=""
                    id=""
                    onChange={(e) =>
                      setCondtion({ ...condition, min: e.target.value })
                    }
                    placeholder="min"
                  />
                  <span>to</span>
                  <input
                    type="number"
                    name=""
                    id=""
                    onChange={(e) =>
                      setCondtion({ ...condition, max: e.target.value })
                    }
                    placeholder="max"
                  />
                </div>
                <div className="r3 button">
                  <button
                    onClick={() => handleApply(condition.min, condition.max)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="p4 ">
              <div className="head">
                <p className="text">Collection</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="search-box-set">
                  <span className="fa fa-search"></span>
                  <input
                    type="text"
                    placeholder="Filter"
                    value={searchTerm}
                    onChange={handleChange}
                  />
                </div>
                <div className="Filter-item-set">
                  <ul>
                    {searchResults.map((item) => (
                      <li>
                        <div className="Collection-img">
                          <div className="Collection-img-set">
                            <img
                              className="user"
                              src="https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s64"
                              alt=""
                            />
                          </div>
                        </div>
                        <p onClick={() => history.push(`/category/${item}`)}>
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
           */}
            {/* <div className="p5 ">
              <div className="head">
                <p className="text">Chains</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="Chains-iteam">
                  <ul>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="https://wallpaperaccess.com/full/1267683.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Ethereum</p>
                    </li>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="https://image.shutterstock.com/image-vector/octagon-icon-geometry-octagonal-on-600w-1929916190.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Polygon</p>
                    </li>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="http://res.heraldm.com/content/image/2019/06/27/20190627000865_0.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Klaytn</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="p6">
              <div className="head">
                <p className="text">Categories</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="Categories">
                  <ul>
                    {category.map((item) => {
                      return (
                        <li>
                          <div className="Collection-img">
                            <div className="Collection-img-set">
                              <img
                                className="user"
                                src="https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s64"
                                alt=""
                              />
                            </div>
                          </div>
                          <p onClick={() => history.push(`/category/${item}`)}>
                            {item}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="p7">
              <div className="head">
                <p className="text">On Sale In</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="search-box-set">
                  <span className="fa fa-search"></span>
                  <input type="text" placeholder="Filter" />
                </div>
                <div className="Sale-iteam">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label for="vehicle1">XMG</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Bike"
                      />
                      <label for="vehicle2">Pecu Novus</label>
                    </li>
                    {/* <li>
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Bike"
                      />
                      <label for="vehicle3">0XBTC</label>
                    </li> */}
                    {/* <li>
                      <input
                        type="checkbox"
                        id="vehicle4"
                        name="vehicle4"
                        value="Bike"
                      />
                      <label for="vehicle4">1337</label>
                    </li> */}
                    {/* <li>
                      <input
                        type="checkbox"
                        id="vehicle5"
                        name="vehicle5"
                        value="Bike"
                      />
                      <label for="vehicle5">1MT</label>
                    </li> */}
                    {/* <li>
                      <input
                        type="checkbox"
                        id="vehicle6"
                        name="vehicle6"
                        value="Bike"
                      />
                      <label for="vehicle6">2XDN</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle7"
                        name="vehicle7"
                        value="Bike"
                      />
                      <label for="vehicle7">AC</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle8"
                        name="vehicle8"
                        value="Bike"
                      />
                      <label for="vehicle8">ADORs</label>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <i
          class="fa fa-bars closeinweb "
          aria-hidden="true"
          onClick={() => {
            setSideDrawer(!sideDrawer);
          }}
        ></i>
      )}

<div className="mainSideBar">
          <div className="left">
            <div className="p1">
              <div>
                <p className="fa fa-sliders"></p>
                <span className="text">Filter</span>
              </div>
            
            </div>
            <div className="p2">
              <div className="head">
                <p className="text">Status</p>
                {/* <input className="tick" type="checkbox" /> */}
                <p className="fa fa-chevron-down" onClick={()=>setStatusMenu(!statusMenu)}></p>
              </div>
              {statusMenu?   <div className="body">
                <span
                  style={{
                    border: `${
                      status.includes("hot_delas") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("hot_delas")}
                >
                  Hot deal
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("on_actions") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("on_actions")}
                >
                  On Auction
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("marketplaces") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("marketplaces")}
                >
                  Marketplace
                </span>
                <span
                  style={{
                    border: `${
                      status.includes("live_actions") ? "1px solid red" : ""
                    }`,
                  }}
                  onClick={() => handleCheckStatus("live_actions")}
                >
                  Live auction
                </span>
              </div>
        :null}
               </div>
            <div className="p3">
              <div className="head">
                <p className="text">Price</p>
                <p className="fa fa-chevron-down" onClick={()=>setPriceMenu(!priceMenu)}></p>
              </div>
              {priceMenu?<div className="body">
                <div className="r1 United-States">
                  <select name="" id="">
                  <option value="2">XMG</option>
                    <option value="1" active>
                      United States Dollar(USD)
                    </option>
                 
                  </select>
                  <p className="fa fa-chevron-down"></p>
                </div>
                <div className="r2 mitter">
                  <input
                    type="number"
                    name=""
                    id=""
                    onChange={(e) =>
                      setCondtion({ ...condition, min: e.target.value })
                    }
                    placeholder="min"
                  />
                  <span>to</span>
                  <input
                    type="number"
                    name=""
                    id=""
                    onChange={(e) =>
                      setCondtion({ ...condition, max: e.target.value })
                    }
                    placeholder="max"
                  />
                </div>
                <div className="r3 button">
                  <button
                    onClick={() => handleApply(condition.min, condition.max)}
                  >
                    Apply
                  </button>
                </div>
              </div>
         :null}
            </div>
            {/* <div className="p4 ">
              <div className="head">
                <p className="text">Collection</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="search-box-set">
                  <span className="fa fa-search"></span>
                  <input
                    type="text"
                    placeholder="Filter"
                    value={searchTerm}
                    onChange={handleChange}
                  />
                </div>
                <div className="Filter-item-set">
                  <ul>
                    {searchResults.map((item) => (
                      <li>
                        <div className="Collection-img">
                          <div className="Collection-img-set">
                            <img
                              className="user"
                              src="https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s64"
                              alt=""
                            />
                          </div>
                        </div>
                        <p onClick={() => history.push(`/category/${item}`)}>
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
           */}
            {/* <div className="p5 ">
              <div className="head">
                <p className="text">Chains</p>
                <p className="fa fa-chevron-down"></p>
              </div>
              <div className="body">
                <div className="Chains-iteam">
                  <ul>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="https://wallpaperaccess.com/full/1267683.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Ethereum</p>
                    </li>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="https://image.shutterstock.com/image-vector/octagon-icon-geometry-octagonal-on-600w-1929916190.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Polygon</p>
                    </li>
                    <li>
                      <div className="Collection-img">
                        <div className="Collection-img-set">
                          <img
                            className="user"
                            src="http://res.heraldm.com/content/image/2019/06/27/20190627000865_0.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>Klaytn</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="p6">
              <div className="head">
                <p className="text">Categories</p>
                <p className="fa fa-chevron-down" onClick={()=>setCategoriesMenu(!categoriesMenu)}></p>
              </div>
              {categoriesMenu? <div className="body">
                <div className="Categories">
                  <ul>
                    {category.map((item) => {
                      return (
                        <li>
                          <div className="Collection-img">
                            <div className="Collection-img-set">
                              {/* <img
                                className="user"
                                src="https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s64"
                                alt=""
                              /> */}
                            </div>
                          </div>
                          <p onClick={() => history.push(`/category/${item}`)}>
                            {item}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
          :null}
           </div>
            <div className="p7">
              <div className="head">
                <p className="text">On Sale In</p>
                <p className="fa fa-chevron-down" onClick={()=>{setsalesMenu(!saleMenu)}}></p>
              </div>
              {saleMenu?     <div className="body">
                <div className="search-box-set">
                  <span className="fa fa-search"></span>
                  <input type="text" placeholder="Filter" />
                </div>
                <div className="Sale-iteam">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label for="vehicle1">XMG</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle2"
                        name="vehicle2"
                        value="Bike"
                      />
                      <label for="vehicle2">ETH</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle3"
                        name="vehicle3"
                        value="Bike"
                      />
                      <label for="vehicle3">Pecu Novus</label>
                    </li>
                    {/* <li>
                      <input
                        type="checkbox"
                        id="vehicle4"
                        name="vehicle4"
                        value="Bike"
                      />
                      <label for="vehicle4">1337</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle5"
                        name="vehicle5"
                        value="Bike"
                      />
                      <label for="vehicle5">1MT</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle6"
                        name="vehicle6"
                        value="Bike"
                      />
                      <label for="vehicle6">2XDN</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle7"
                        name="vehicle7"
                        value="Bike"
                      />
                      <label for="vehicle7">AC</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="vehicle8"
                        name="vehicle8"
                        value="Bike"
                      />
                      <label for="vehicle8">ADORs</label>
                    </li> */}
                  </ul>
                </div>
              </div>
          :null}
           </div>
          </div>
        </div>
   
    </div>
  );
};

export default Sidebar;
