import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  createProduct,
  deleteProduct,
  listProducts,
} from "../actions/productActions";
import CreateAuction from "../components/CreateAuction";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
} from "../constants/productConstants";

export default function ProductListScreen(props) {
  const sellerMode = props.match.path.indexOf("/seller") >= 0;
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;
  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET });
      props.history.push(`/product/${createdProduct._id}/edit`);
    }
    if (successDelete) {
      dispatch({ type: PRODUCT_DELETE_RESET });
    }
    dispatch(listProducts({ seller: sellerMode ? userInfo._id : "" }));
  }, [createdProduct, dispatch, props.history, successCreate, successDelete]);

  const deleteHandler = (product) => {
    if (window.confirm("Are you sure to delete?")) {
      dispatch(deleteProduct(product._id));
    }
  };
  const createHandler = () => {
    dispatch(createProduct());
  };

  const [isNFT,setISNFT]=useState(false)
  const [NFTData,setNFTData]=useState()
  return (
    <div >
      {isNFT?<div id="addNFT" style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundImage: `url(${NFTData.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}><div className="card" style={{display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:"center",padding:'10px'}}>
        <h3 >Add NFT </h3>
        <h4 >{NFTData.name}</h4>
       
        <input type="text" placeholder="Public Key" style={{minWidth:'300px',margin:'10px'}} />
        <input type="text" placeholder="Private Key" style={{minWidth:'300px',margin:'10px'}} />
        <div style={{display:'flex'}}>
        <button type="button" className="primary" style={{backgroundColor:'red',color:'white'}} onClick={()=>{setISNFT(false)}}>Cancel</button>

          <button type="button" className="primary">Update Product</button>
              </div>
         </div></div>:null}
    
      
      <div className="row">
        <h1>Products</h1>
        <button type="button" className="primary" onClick={createHandler}>
          Create Product
        </button>
      </div>

      {loadingDelete && <LoadingBox></LoadingBox>}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}

      {loadingCreate && <LoadingBox></LoadingBox>}
      {errorCreate && <MessageBox variant="danger">{errorCreate}</MessageBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th>ACTIONS</th>
                <th>NFT</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>
                    {product._id} <img className="small" src={product.image} />{" "}
                  </td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <button
                      type="button"
                      className="small"
                      onClick={() =>
                        props.history.push(`/product/${product._id}/edit`)
                      }
                    >
                      Edit Me
                    </button>
                    <button
                      type="button"
                      className="small"
                      onClick={() => deleteHandler(product)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="small"
                      onClick={() =>
                        props.history.push(`/auction/${product._id}/edit`)
                      }
                    >
                      Create Auction
                    </button>
                  </td>
                  <td><a href="#addNFT"><button  type="button"
                      className="small" onClick={()=>{setNFTData(product);setISNFT(true)}}>Add NFT</button></a></td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="row center pagination">
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === page ? "active" : ""}
                key={x + 1}
                to={`/productlist/pageNumber/${x + 1}`}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
